<template>
  <div class="row">
    <b-modal
      v-if="showDetailModal"
      id="display-detail-modal"
      ref="display-detail-modal"
      hide-header
      hide-header-close
      scrollable
      size="xl"
    >
      <div class="row mx-0 py-7">
        <div class="col-12 mx-0 py-2 text-sm">
          <div
            class="col-12 py-2 px-0 text-center text-sm font-weight-boldest navy-blue-border-bottom-1px bg-fine"
          >
            {{ invoicesDetails[0].supplier_company.name_for_accounting }}
          </div>
          <template>
            <div class="col-12 d-flex px-0 justify-content-start">
              <div
                class="w-30 py-1 pl-2 pr-2 text-center font-weight-bold navy-blue-border-bottom-1px navy-blue-border-right-1px navy-blue-border-left-1px"
                style="width: 41.8px"
              >
                {{ $t("general.select") }}
              </div>
              <div
                class="w-40 py-1 text-center font-weight-bold navy-blue-border-bottom-1px navy-blue-border-right-1px"
              >
                {{ $t("invoice.company_name") }}
              </div>
              <div
                class="w-100px py-1 text-center font-weight-bold navy-blue-border-bottom-1px navy-blue-border-right-1px"
              >
                {{ $t("invoice.invoice_type") }}
              </div>
              <div
                class="w-100px py-1 text-center font-weight-bold navy-blue-border-bottom-1px navy-blue-border-right-1px"
              >
                {{ $t("invoice.invoice_kind") }}
              </div>
              <div
                class="w-100px py-1 text-center font-weight-bold navy-blue-border-bottom-1px navy-blue-border-right-1px"
              >
                {{ $t("invoice.action_date") }}
              </div>
              <div
                class="w-100px py-1 text-center font-weight-bold navy-blue-border-bottom-1px navy-blue-border-right-1px"
              >
                {{ $t("invoice.invoice_date") }}
              </div>
              <div
                class="w-40 py-1 text-center font-weight-bold navy-blue-border-bottom-1px navy-blue-border-right-1px"
              >
                {{ $t("invoice.invoice_number") }}
              </div>
              <div
                class="w-100px py-1 text-center font-weight-bold navy-blue-border-bottom-1px navy-blue-border-right-1px"
              >
                {{ $t("invoice.quantity") }}
              </div>
            </div>
            <template v-for="(invoice, index) in invoicesDetails">
              <div
                class="col-12 d-flex px-0 justify-content-center navy-blue-border-bottom-1px"
                :class="{ 'checked-approved-row': invoice.paid_up_status >= 45 }"
              >
                <div
                  class="w-20 d-flex justify-content-center align-items-center font-weight-bold navy-blue-border-right-1px navy-blue-border-left-1px"
                  style="width: 46px"
                >
                  <v-checkbox
                    v-model="invoice.paid_up_status"
                    :id="index + '_check'"
                    :value="invoice.paid_up_status"
                    :disabled="invoice.paid_up_status >= 45 ? true : false"
                    class="m-0 p-0"
                    size="5"
                    hide-details
                  >
                  </v-checkbox>
                </div>
                <div
                  class="w-40 py-1 text-center font-weight-bold navy-blue-border-right-1px"
                >
                  {{ invoice.supplier_company.name_for_accounting }}
                </div>
                <div
                  class="w-100px py-1 text-center font-weight-bold navy-blue-border-right-1px"
                >
                  {{ invoice.invoice_type.translations[0].name }}
                </div>
                <div
                  class="w-100px py-1 text-center font-weight-bold navy-blue-border-right-1px"
                >
                  {{ invoice.invoice_category.translations[0].name }}
                </div>
                <div
                  class="w-100px py-1 text-center font-weight-bold navy-blue-border-right-1px"
                >
                  {{ moment(invoice.operation_date).format("DD.MM.YYYY") }}
                </div>
                <div
                  class="w-100px py-1 text-center font-weight-bold navy-blue-border-right-1px"
                >
                  {{ moment(invoice.invoice_date).format("DD.MM.YYYY") }}
                </div>
                <div
                  class="w-40 py-1 text-center font-weight-bold navy-blue-border-right-1px"
                >
                  {{ invoice.invoice_no }}

                  <span :class="{ 'disabled': invoice.paid_up_status == 57 }"
                  v-if="invoice.paid_up_status == 57"
                  v-html="getIconByKey('icons.sample.delete_red', {
                    class: 'w-25px h-25px m-0 p-0 object-cover d-inline-block opacity-75 mr-2',
                  })"></span>
                  <span 
                  v-if="invoice.paid_up_status < 45 && isUserGranted('Staff', ['financeManager'], false, true)"
                  @click="outOfPayment(invoice.id,index)"
                  v-html="getIconByKey('icons.sample.delete_red', {
                    class: 'w-25px h-25px m-0 p-0 object-cover d-inline-block opacity-75 mr-2',
                  })"></span>
                </div>
                <!-- eğer bu tevkifatlı fatura ise gösterilen tutar toplam tutatrın tl cinsine dönüştürülmüş hali yazılıyor -->
                <div
                  class="w-100px py-1 text-center font-weight-bold navy-blue-border-right-1px"
                >
                  {{
                    invoice.withholding_amount > 0
                      ? invoice.amount_to_be_paid
                      : invoice.total_amount_including_taxes | invoiceFormat
                  }}
                  {{ invoice.currency_unit.code }}
                </div>
              </div>
              <div
                class="col-12 navy-blue-border-bottom-1px p-0 m-0 navy-blue-bottom-top-1px d-flex justify-content-end"
                v-if="
                  invoice.invoice_payment_request_item != null &&
                  invoice.invoice_payment_request_item.total_amount > 0
                "
              >
                <div class="w-100 py-1 text-right mr-2 font-weight-bold text-right">
                  {{ $t("general.description ") }} :
                  {{ invoice.invoice_payment_request_item.description }}
                  /
                  {{ $t("approved_invoices.paid_amount") }} :
                  {{ invoice.invoice_payment_request_item.total_amount | invoiceFormat }}
                  {{ invoice.currency_unit.code }}
                </div>
              </div>
            </template>

            <div class="row">
              <div class="col-6">
                <div class="col-12 d-flex px-0">
                  <div
                    class="w-200px py-1 text-center font-weight-bold navy-blue-border-right-1px navy-blue-border-left-1px"
                  >
                    {{ $t("invoice.total") }}
                  </div>
                </div>
                <div
                  class="col-12 d-flex px-0 mt-1"
                  v-for="rowItem in selectedRowsTotals.items"
                >
                  <div
                    class="w-200px py-1 text-center font-weight-bold navy-blue-border-1px"
                  >
                    {{ rowItem.total | invoiceFormat }} {{ rowItem.currency_unit }}
                  </div>
                </div>
                <div class="col-12 d-flex px-0 mt-1">
                  <div
                    v-if="selectedRowsTotals.total > 0"
                    class="w-200px py-1 text-center font-weight-bold navy-blue-border-1px"
                  >
                    TL {{ $t("invoice.total") }}:
                    {{ selectedRowsTotals.total | invoiceFormat }} TL
                  </div>
                </div>

                <!-- yeni tiklenecek yerler için -->
                <div class="col-12 d-flex px-0">
                  <div
                    class="w-200px py-1 text-center font-weight-bold navy-blue-border-right-1px navy-blue-border-left-1px"
                  >
                    {{ $t("invoice.selected_total") }}
                  </div>
                </div>
                <div
                  class="col-12 d-flex px-0 mt-1"
                  v-for="rowNewItem in selectedRowsTotals.newItems"
                >
                  <div
                    class="w-200px py-1 text-center font-weight-bold navy-blue-border-1px"
                  >
                    {{ rowNewItem.total | invoiceFormat }} {{ rowNewItem.currency_unit }}
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div v-for="currency in forCurrencyKeyTotals">
                  <div class="col-12 d-flex px-0 justify-content-end">
                    <div
                      class="w-200px py-1 text-center font-weight-bold navy-blue-border-bottom-1px navy-blue-border-right-1px navy-blue-border-left-1px"
                    >
                      {{ currency["currency_type"] }} {{ $t("invoice.total") }}
                    </div>
                  </div>
                  <div class="col-12 d-flex px-0 justify-content-end">
                    <div
                      class="w-200px py-1 text-center font-weight-bold navy-blue-border-right-1px navy-blue-border-bottom-1px navy-blue-border-left-1px"
                    >
                      {{ currency["total"] | invoiceFormat }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
      <template #modal-footer>
        <div class="col-12 text-center">
          <button
            class="btn btn-sm btn-outline-danger p-2 btn-pill font-weight-bold cursor-pointer mr-5"
            @click="closeModalDetail('display-detail-modal')"
          >
            {{ $t("general.close") }}
          </button>
          <button
            class="btn btn-sm btn-outline-primary p-2 btn-pill font-weight-bold cursor-pointer mr-5"
            @click="saveOnCheckedInvoiceStatus()"
            v-if="isUserGranted('Staff', ['financeManager'], false, true)"
          >
            {{ $t("invoice.send_to_invoice_managament") }}
          </button>
        </div>
      </template>
    </b-modal>
    <modal :adaptive="true" height="auto" name="open_to_settings_table" width="300">
      <div class="row mx-0 py-7">
        <div
          class="col-12 row mx-0 py-2 navy-blue-border-bottom-1px text-xl font-weight-bold"
        >
          <div class="col-6 text-center">{{ $t("general.colum_name") }}</div>
          <div class="col-6 text-center">{{ $t("general.add") }}</div>
        </div>
        <div class="col-12 mx-0 py-2 text-xl">
          <draggable
            v-model="fieldsToViewEdit"
            v-bind="dragOptions"
            :move="onMove"
            class="list-group py-4"
            tag="ul"
            @end="isDragging = false"
            @start="isDragging = true"
          >
            <transition-group :name="'flip-list'" type="transition">
              <div
                v-for="element in fieldsToViewEdit"
                :key="element.sort"
                class="col-12 list-group-item w-300px d-flex justify-content-between"
              >
                <span class="col-6"> {{ $t(element.name) }}</span>

                <span class="col-6 d-flex justify-content-center">
                  <input
                    v-model="element.type_status"
                    style="font-size: 10px"
                    type="checkbox"
                /></span>
              </div>
            </transition-group>
          </draggable>
          <button
            class="btn btn-sm btn-outline-danger p-2 btn-pill font-weight-bold cursor-pointer mr-5"
            @click="closeModal"
          >
            {{ $t("esc.new_send_close").toUpperCase() }}
          </button>
          <button
            class="btn btn-sm btn-outline-primary btn-pill p-2 font-weight-bold cursor-pointer"
            @click="onSubmitToSaveSettingTable"
          >
            {{ $t("esc.new_send") }}
          </button>
        </div>
      </div>
    </modal>
    <div class="col-md-12">
      <form @submit.prevent="filterResult(null)">
        <div id="filter-card" class="row py-3 px-4" v-if="filters">
          <div class="col-lg-12 col-xl-12 px-0">
            <div class="row align-items-end py-0 my-0 mx-0">
              <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 my-2 row px-0 mx-0 py-1">
                <input-filter
                  :helper-text="$t('general.search')"
                  :model.sync="filters.search"
                  :placeholder="$t('general.search_filter')"
                  title="general.search"
                ></input-filter>
              </div>
              <div class="col-xl-2 col-lg-2 col-md-2 my-lg-2 my-sm-0 row mx-0 px-0 py-1">
                <div class="col-lg-6 col-md-6 col-sm-12 my-2 my-md-0">
                  <date-filter
                    :helper-text="$t('general.start_date')"
                    :model.sync="filters.start_date"
                    name="start_time"
                    title="general.start_date"
                  >
                  </date-filter>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12 my-2 my-md-0">
                  <date-filter
                    :helper-text="$t('general.end_date')"
                    :model.sync="filters.end_date"
                    name="end_date"
                    title="general.end_date"
                  >
                  </date-filter>
                </div>
              </div>
              <div class="col-xl-1 col-lg-1 col-md-1 col-sm-12">
                <custom-multi-select
                  :required="false"
                  :model.sync="filters.expiry_id"
                  :options="expiries"
                  :title="$t('general.select_expiry')"
                  :itemPerRow="1"
                  :multiple="false"
                  :is-inline="false"
                  :not-list="true"
                  :max="1"
                  name="expiries"
                  :showInvalid="false"
                />
              </div>
              <div class="col-xl-1 col-lg-1 col-md-1 col-sm-12">
                <custom-multi-select
                  :required="false"
                  :model.sync="filters.instruction_type"
                  :options="instructionType"
                  :title="$t('instruction_type.instruction_types')"
                  :itemPerRow="1"
                  :multiple="false"
                  :is-inline="false"
                  :not-list="true"
                  :max="1"
                  name="instructionType"
                  :showInvalid="false"
                />
              </div>
              <div class="col-xl-1 col-lg-1 col-md-1 col-sm-12">
                <custom-multi-select
                  :required="false"
                  :model.sync="filters.order_type"
                  :options="orderTypeOptions"
                  :title="$t('general.order_types')"
                  :itemPerRow="1"
                  :multiple="false"
                  :is-inline="false"
                  :not-list="true"
                  :max="1"
                  name="orderTypes"
                  :showInvalid="false"
                />
              </div>
              <div class="col-xl-1 col-lg-1 col-md-1 col-sm-12">
                <custom-multi-select
                  :required="false"
                  :model.sync="filters.order_id"
                  :options="orderNumbers"
                  :title="$t('invoice.order_umber')"
                  :itemPerRow="1"
                  :multiple="false"
                  :is-inline="false"
                  :not-list="true"
                  :max="1"
                  name="orderTypes"
                  :showInvalid="false"
                />
              </div>
              <div
                class="col-xl-2 my-lg-2 col-md-3 col-sm-12 mt-3 px-1 d-flex align-items-center justify-content-end m-0 pr-0 row"
                style="margin: 0 !important"
              >
                <div class="col-12 pr-0">
                  <button
                    class="w-100 btn btn-sm btn-outline-primary rounded-full font-weight-bold py-1"
                    @click.prevent="filterResult(null)"
                  >
                    {{ $t("general.filter") }}
                  </button>
                </div>
                <div class="col-12 pr-0">
                  <button
                    class="w-100 btn btn-sm btn-outline-primary rounded-full font-weight-bold mt-2 py-1"
                    @click="resetFilters"
                  >
                    {{ $t("general.clear") }}
                  </button>
                </div>
                <div class="col-12 pr-0">
                  <button @click="exportApprovedInvoiceTable" type="button"
                          class="w-100 btn btn-sm btn-outline-primary rounded-full font-weight-bold mt-2 py-1">
                    {{ $t('general.export_to_excel') }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>

    <div class="col-md-12">
      <dashboard-box>
        <template v-slot:preview>
          <div class="px-5 py-3">
            <div
              class="fixed"
              style="position: fixed !important; top: 255px; right: 30px"
            >
              <div
                class="w-60px h-60px"
                style="z-index: 99999"
                @click="openPopToSettingTable"
              >
                <span
                  v-html="
                    getIconByKey('icons.waybill.edit', {
                      class: 'w-40px h-40px d-inline-block object-fill cursor-pointer',
                    })
                  "
                >
                </span>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <span class="h4 float-right mr-4">
                  <b>{{ $t("invoice.total") }} :</b> {{ totalForTable | invoiceFormat }}
                </span>
                <span class="h4 float-right mr-4">
                  <b>{{ $t("invoice.unpaid_total") }} :</b> {{ totalUnpaidForTable | invoiceFormat }}
                </span>
              </div>
            </div>
            <data-table
              :bordered="false"
              :borderless="true"
              :fields.sync="tableFields"
              :hover="false"
              :items="formattedItems"
              :no-border-collapse="true"
              :outlined="false"
              :striped="false"
              tableVariant="''"
              :sortBy.sync="order"
              :sortDesc.sync="sort"
              @rowClicked="onRowClicked"
              :infiniteId="infiniteId"
            ></data-table>
            <div class="row">
              <div class="col-12">
                <span class="h4 float-right mr-4">
                  <b>{{ $t("invoice.total") }} :</b> {{ totalForTable | invoiceFormat }}
                </span>
                <span class="h4 float-right mr-4">
                  <b>{{ $t("invoice.unpaid_total") }} :</b> {{ totalUnpaidForTable | invoiceFormat }}
                </span>
              </div>
            </div>
          </div>
        </template>
      </dashboard-box>
    </div>
  </div>
</template>

<script>
import DashboardBox from "@/assets/components/DashboardBox";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import SelectFilter from "@/assets/components/filters/SelectFilter";
import { mapGetters, mapMutations } from "vuex";
import {
  GET_ITEMS,
  HANDLE_INFINITE_SCROLL,
  LOADING,
  SET_ITEMS,
  SET_LOADING,
  UPDATE_ITEM_BY_ID,
  GET_ITEM_DETAIL_BY_ID,
  EXPORT,
  FILTER,
  SET_FILTER,
  CREATE_ITEM,
} from "@/core/services/store/invoice/approved_invoices.module";
import moment from "moment";
import DateFilter from "@/assets/components/filters/DateFilter";
import InputFilter from "@/assets/components/filters/InputFilter";
import DataTable from "@/assets/components/dataTable/DataTable";
import { LARAVEL_DATE_FORMAT, MOMENT_SYSTEM_DATE_FORMAT } from "@/core/config/constant";
import { invoiceFormat } from "@/core/filter/number.filter.js";
import * as _ from "lodash";
import draggable from "vuedraggable";
import CustomMultiSelect from "@/assets/components/inputs/CustomMultiSelect";
import {
  APPROVED_INVOICES_INDEX_END_DATE_STORAGE_NAME,
  APPROVED_INVOICES_INDEX_START_DATE_STORAGE_NAME,
} from "@/core/storage/storage-names";

export default {
  name: "InvoiceIndex",
  components: {
    DashboardBox,
    DateFilter,
    InputFilter,
    DataTable,
    draggable,
    SelectFilter,
    CustomMultiSelect,
  },
  computed: {
    ...mapGetters({
      loading: LOADING,
      filter: FILTER,
    }),
    filters: {
      get: function () {
        return this.filter;
      },
      set: function (value) {
        this.setFilter(value);
      },
    },
    moment: () => moment,
    selectedRowsTotals() {
      let forCurrencyTypeList = [];
      let forNewCurrencyTypeList = [];
      let totalTl = 0;
      let self = this;
      this.invoicesDetails.forEach(function (selectItem) {

        let findItemNew = forNewCurrencyTypeList.findIndex(
          (item) => item.currency_unit_id === selectItem.currency_unit.id
        );
        if (selectItem.paid_up_status === true) {
          if (findItemNew !== -1) {
            if (selectItem.withholding_amount > 0) {
              forNewCurrencyTypeList[findItemNew].total =
                      Number(forNewCurrencyTypeList[findItemNew].total) +
                      Number(selectItem.amount_to_be_paid);
            }else{
              forNewCurrencyTypeList[findItemNew].total =
                Number(forNewCurrencyTypeList[findItemNew].total) +
                Number(selectItem.total_amount_including_taxes);
            }

          }else{
            forNewCurrencyTypeList.push({
                currency_unit_id: +selectItem.currency_unit.id,
                currency_unit: selectItem.currency_unit.code,
                total:
                  selectItem.withholding_amount > 0
                    ? +selectItem.amount_to_be_paid
                    : +selectItem.total_amount_including_taxes,
              });
          }
      }
        

        if (selectItem.paid_up_status >= 45 || selectItem.paid_up_status === true) {
          let findItem = forCurrencyTypeList.findIndex(
            (item) => item.currency_unit_id === selectItem.currency_unit.id
          );
          
          if (findItem !== -1) {
            if (selectItem.withholding_amount > 0) {
              forCurrencyTypeList[findItem].total =
                Number(forCurrencyTypeList[findItem].total) +
                Number(selectItem.amount_to_be_paid);

            } else {
              forCurrencyTypeList[findItem].total =
                Number(forCurrencyTypeList[findItem].total) +
                Number(selectItem.total_amount_including_taxes);
            }
          } else {
            forCurrencyTypeList.push({
              currency_unit_id: +selectItem.currency_unit.id,
              currency_unit: selectItem.currency_unit.code,
              total:
                selectItem.withholding_amount > 0
                  ? +selectItem.amount_to_be_paid
                  : +selectItem.total_amount_including_taxes,
            });
          }
          self.showSlectedInvoiceRowTotal = true;
          if (
            !(selectItem.currency_unit_id == 160 || selectItem.currency_unit_id == 53)
          ) {
            totalTl +=
              selectItem.withholding_amount > 0
                ? Number(selectItem.amount_to_be_paid)
                : Number(selectItem.total_amount_including_taxes_converted);
          }
        }
      });
      let data = {
        items: forCurrencyTypeList,
        newItems: forNewCurrencyTypeList,
        total: totalTl,
      };
      return data;
    },
    formattedItems() {
      if (!this.items || this.items.data === null || this.items.data === undefined)
        return [];
      this.totalForTable = 0;
      this.totalUnpaidForTable = 0;
      return this.items.data.map((invoice) => {
        this.totalForTable = this.totalForTable + invoice.total;
        this.totalUnpaidForTable=this.totalUnpaidForTable+invoice.unpaid_total;
        return {
          id: invoice.id,
          supplier_company_id: invoice.company_name,
          amount_to_be_paid: invoiceFormat(invoice.total) + " " + this.defaultCurrency,
          invoice_no: invoice.invoice_no.join(",\n"),
          unpaid_total: invoiceFormat(invoice.unpaid_total) + " " + this.defaultCurrency
        };
      });
    },
    instructionType() {
      let instructionType = [
        {
          name: "Kumaş",
          id: 1,
        },
        {
          name: "Masraf",
          id: 2,
        },
      ];
      return this.convertArrayToObjectByKey(instructionType, "id", "name");
    },
    orderTypeOptions() {
      let orderTypes = [
        {
          name: "Siparişe Bağlı Olmayan Fatura Türleri",
          id: 1,
        },
      ];
      return this.convertArrayToObjectByKey(orderTypes, "id", "name");
    },
    dragOptions() {
      return {
        animation: 0,
        group: "description",
        disabled: !this.editable,
        ghostClass: "ghost",
      };
    },
    listString() {
      return JSON.stringify(this.list, null, 2);
    },
    tableFields() {
      let fields = [];
      let self = this;
      for (const [key, value] of Object.entries(self.modelTableItem[0].table_fields)) {
        if (value.status === true) {
          fields.push({
            key: key,
            value: this.filter && this.filter.hasOwnProperty(key) ? this.filter[key] : "",
            label: this.$t(value.name),
            sortable: value.status,
            sort: value.sort,
            class: "text-center border border-top-0 border-left-0",
            tdClass: "cursor-pointer",
          });
        }
      }
      return _.sortBy(fields, ["sort"]);
    },
  },
  data() {
    return {
      infiniteId: 1,
      editable: true,
      defaultCurrency: "TL",
      isDragging: false,
      delayedDragging: false,
      expiries: [],
      orderNumbers: [],
      sampleStatuses: [],
      fieldsToViewEdit: [],
      showDetailModal: false,
      url: "api/invoices/approved-invoices",
      items: { data: [] },
      startDate: moment().subtract(30, "d").format(LARAVEL_DATE_FORMAT),
      endDate: moment().add(1, "d").format(LARAVEL_DATE_FORMAT),
      page: 1,
      perPage: 25,
      sort: "desc",
      order: "id",
      invoicesDetails: [],
      forCurrencyKeyTotals: [],
      totalForTable: 0,
      totalUnpaidForTable: 0,
      selectedInvoiceRow: [],
      showSlectedInvoiceRowTotal: false,
      modelTableItem: [
        {
          user_id: null,
          model_name: null,
          table_fields: {},
        },
      ],
    };
  },
  methods: {
    ...mapMutations({
      setFilter: SET_FILTER,
    }),
    getTableItem() {
      let self = this;
      this.$store
        .dispatch(GET_ITEMS, {
          url: "api/model_table",
          filters: {
            model_name: "approved_invoices",
          },
          acceptPromise: true,
          showLoading: false,
        })
        .then((result) => {
          if (result.status) {
            for (let i = 0; i < result.data.length; i++) {
              self.modelTableItem = result.data;
              self.modelTableItem[i].table_fields = JSON.parse(
                result.data[i].table_fields
              );
            }
            for (const [key, value] of Object.entries(
              self.modelTableItem[0].table_fields
            )) {
              self.fieldsToViewEdit.push({
                name: this.$t(value.name),
                sort: value.sort,
                type: value.type,
                type_status: value.status,
                key: key,
              });
              self.fieldsToViewEdit = _.sortBy(self.fieldsToViewEdit, ["sort"]);
            }
          }
        });
    },
    orderList() {
      this.modelTableItem[0].table_fields = this.modelTableItem[0].table_fields.sort(
        (one, two) => {
          return one.sort - two.sort;
        }
      );
    },
    onMove({ relatedContext, draggedContext }) {
      const relatedElement = relatedContext.element;
      const draggedElement = draggedContext.element;
      return (!relatedElement || !relatedElement.status) && !draggedElement.status;
    },
    openPopToSettingTable() {
      this.$modal.show("open_to_settings_table");
    },
    closeModalDetail(name = "display-detail-modal") {
      this.$refs[name].hide();
    },
    closeModal() {
      this.$modal.hide("open_to_settings_table");
    },
    onSubmitToSaveSettingTable() {
      let self = this;
      let json = {};
      for (let i = 0; i < this.fieldsToViewEdit.length; i++) {
        json[this.fieldsToViewEdit[i].key] = {
          name: this.fieldsToViewEdit[i].name,
          type: this.fieldsToViewEdit[i].type,
          status: this.fieldsToViewEdit[i].type_status,
          sort: i,
        };
      }
      let contents = {
        model_name: "approved_invoices",
        table_fields: json,
      };
      let payload = {
        url: "api/model_table/" + this.modelTableItem[0].id,
        id: this.id,
        contents: contents,
      };
      this.$store.dispatch(UPDATE_ITEM_BY_ID, payload).then((result) => {
        if (result.status) {
          this.$modal.hide("open_to_settings_table");
          self.sweetAlertSuccess(self.$t("general.successfully_created"));
          this.fieldsToViewEdit = [];
          this.getTableItem();
        } else {
          self.sweetAlertError(self.$t("general.internal_error"));
        }
      });
    },
    filterResult() {
      localStorage.setItem(
        APPROVED_INVOICES_INDEX_END_DATE_STORAGE_NAME,
        moment(this.filters.start_date)
      );
      localStorage.setItem(
        APPROVED_INVOICES_INDEX_START_DATE_STORAGE_NAME,
        moment(this.filters.end_date)
      );

      let self = this;
      this.page = 1;
      this.filters.page = 1;
      this.infiniteId++;
      this.onHitBottom();
      if (self.state) {
        setTimeout(() => {
          self.state.reset();
        }, 100);
      }
    },
    onHitBottom($state = null) {
      let self = this;
      if (self.isUserGranted("Invoice", ["viewAny"])) {
        let filters = _.cloneDeep(this.filters);
        this.$set(
          filters,
          "start_date",
          moment(this.filters.start_date).format(LARAVEL_DATE_FORMAT)
        );
        this.$set(
          filters,
          "end_date",
          moment(this.filters.end_date).format(LARAVEL_DATE_FORMAT)
        );
        this.$set(filters, "order", this.order);
        this.$set(filters, "sort", this.sort);
        this.$set(filters, "per_page", this.per_page);

        self.$store
          .dispatch(HANDLE_INFINITE_SCROLL, {
            url: self.url,
            filters: filters,
            $state: $state,
          })
          .then((result) => {
            if (result.status) {
              self.items = result.data;
              if (!(result.data && result.data.data && result.data.data.length)) {
                $state.complete();
              }
              if ($state) {
                $state.loaded();
              }
            } else {
              if ($state) {
                $state.complete();
              }
            }
          });
      }
    },
    resetFilters() {
      this.setFilter({
        start_date: moment().subtract(30, "d"),
        end_date: moment().add(1, "d"),
        search: null,
        sort: "",
        order: "",
        is_service: 0,
        page: 1,
        per_page: 10,
      });
    },
    onRowClicked(item, index, event) {
      this.selectedInvoiceRow = [];
      let filters = _.cloneDeep(this.filters);
      this.$set(
        filters,
        "start_date",
        moment(this.filters.start_date).format(LARAVEL_DATE_FORMAT)
      );
      this.$set(
        filters,
        "end_date",
        moment(this.filters.end_date).format(LARAVEL_DATE_FORMAT)
      );

      this.$store.commit(SET_LOADING, true);

      let companyID = item.id;
      if (companyID) {
        let payload = {
          url: "api/invoices/approved-invoices/" + companyID,
          filters: filters,
        };
        this.$store.dispatch(GET_ITEMS, payload).then((response) => {
          if (response.status) {
            let tmpData = response.data;
            this.invoicesDetails = tmpData;

            this.selectedInvoiceRow = [];
            this.showDetailModal = true;
            let currencyTotal = 0;
            let currency = "";
            let currencyId = "";
            this.forCurrencyKeyTotals = [];
            let currencyArray = _.groupBy(response.data, "currency_unit_id");
            //toplam fatura tutarını sağ alt kısımda yazdırmak için her bir döviz cinsi kendi içerisinde hesaplanıyor.
            for (const [key, value] of Object.entries(currencyArray)) {
              currencyId = key;
              for (const [key, subItem] of Object.entries(value)) {
                currency = subItem.currency_unit.code;
                // eğer bu tevkifatlı fatura ise gösterilen tutar toplam tutatrın tl cinsine dönüştürülmüş hali yazılıyor
                if (subItem.withholding_amount > 0) {
                  currencyTotal =
                    Number(currencyTotal) + Number(subItem["amount_to_be_paid"]);
                } else {
                  currencyTotal =
                    Number(currencyTotal) +
                    Number(subItem["total_amount_including_taxes"]);
                }
              }
              this.forCurrencyKeyTotals.push({
                currency_id: currencyId,
                total: currencyTotal,
                currency_type: currency,
              });
              currencyTotal = 0;
            }
            this.$store.commit(SET_LOADING, false);
            setTimeout(() => {
              this.$refs["display-detail-modal"].show();
            }, 600);
          } else {
            this.$store.commit(SET_LOADING, false);
            self.sweetAlertError(self.$t("general.internal_error"));
          }
        });
      }
    },
    exportInvoiceTable() {
      let filters = _.cloneDeep(this.filters);
      this.$set(
        filters,
        "start_date",
        moment(this.filters.start_date).format(LARAVEL_DATE_FORMAT)
      );
      this.$set(
        filters,
        "end_date",
        moment(this.filters.end_date).format(LARAVEL_DATE_FORMAT)
      );
      this.$set(filters, "order", this.order);
      this.$set(filters, "sort", this.sort);

      this.$store
        .dispatch(EXPORT, {
          filters: filters,
        })
        .then((result) => {
          if (result.status) {
            let fileURL = window.URL.createObjectURL(new Blob([result.data]));
            let fileLink = document.createElement("a");
            fileLink.href = fileURL;
            let day = moment().format("YYYYMMDDHHMMSS");
            let fileName = "invoice" + day + ".xlsx";
            fileLink.setAttribute("download", fileName);
            document.body.appendChild(fileLink);
            fileLink.click();
          }
        });
    },
    getExpiries() {
      this.$store
        .dispatch(GET_ITEMS, {
          url: "api/expiries",
        })
        .then((result) => {
          this.expiries = this.convertArrayToObjectByKey(result.data, "id", "name");
        });
    },
    getOrderNumbers() {
      this.$store
        .dispatch(GET_ITEMS, {
          url: "api/all-orders",
        })
        .then((result) => {
          this.orderNumbers = this.convertArrayToObjectByKey(
            result.data,
            "id",
            "order_number"
          );
        });
    },
    saveOnCheckedInvoiceStatus() {
      if (this.isUserGranted("Staff", ["financeManager"], true, true)) {
        this.$store.commit(SET_LOADING, true);
        let invoices = _.cloneDeep(this.invoicesDetails);
        let formatedInvoices = [];
        let supplierCompanyId = this.invoicesDetails[0].supplier_company.id;

        invoices.forEach((item) => {
          if (item.paid_up_status == true) {
            let total = item.withholding_amount > 0
                      ? item.amount_to_be_paid
                      : item.total_amount_including_taxes | invoiceFormat
            formatedInvoices.push({
              id: item.id,
              paid_up_status: 45,
              total: total,
            });
          }
        });
        let tmpItem = {
          invoices: formatedInvoices,
          supplier_company_id: supplierCompanyId,
          start_date: moment(this.filters.start_date).format(LARAVEL_DATE_FORMAT),
          end_date: moment(this.filters.end_date).format(LARAVEL_DATE_FORMAT),
        };

        let payload = {
          url: "api/invoices/approved-invoices-change-paid-up",
          contents: tmpItem,
        };
        this.$store.dispatch(CREATE_ITEM, payload).then((response) => {
          if (response.status) {
            this.$store.commit(SET_LOADING, false);
            this.sweetAlertSuccess(this.$t("general.success"));
            this.$refs["display-detail-modal"].hide();
          } else {
            self.sweetAlertError(self.$t("general.internal_error"));
          }
        });
      }
      return false;
    },
    exportApprovedInvoiceTable() {
      let filters = _.cloneDeep(this.filters);
        this.$set(
          filters,
          "start_date",
          moment(this.filters.start_date).format(LARAVEL_DATE_FORMAT)
        );
        this.$set(
          filters,
          "end_date",
          moment(this.filters.end_date).format(LARAVEL_DATE_FORMAT)
        );
        this.$set(filters, "order", this.order);
        this.$set(filters, "sort", this.sort);
        this.$set(filters, "per_page", this.per_page);
      this.$store.dispatch(EXPORT, {
        filters: filters,
      }).then(result => {
        if(result.status) {
          let fileURL = window.URL.createObjectURL(new Blob([result.data]));
          let fileLink = document.createElement('a');
          fileLink.href = fileURL;
          let day = moment().format('YYYYMMDDHHMMSS');
          let fileName = 'approved_invoices_' + day + '.xlsx';
          fileLink.setAttribute('download', fileName);
          document.body.appendChild(fileLink);
          fileLink.click();
        }
      })
    },
    outOfPayment(id,index){
      let self = this;
      this.$store
        .dispatch(GET_ITEMS, {
          url: "api/invoices/invoice-payment-request/out-of-payment/"+id,
          acceptPromise: true,
          showLoading: false,
        })
        .then((result) => {
          if (result.status) {
            self.sweetAlertSuccess(self.$t('general.successfully_created')).then(()=>{
              this.invoicesDetails[index]['paid_up_status']=57
            })
          }else{
            self.sweetAlertError(self.$t('general.internal_error'));
          }
        });
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("general.dashboard"), route: "dashboard" },
      { title: this.$t("invoice.invoices") },
    ]);
    let self = this;

    if (this.isUserGranted("Invoice", ["viewAny"])) {
      if (!this.filter) {
        this.resetFilters();
      }

      let parameterOrderNumber = this.$route.params.order_number;
      if (parameterOrderNumber) {
        this.$set(this.filters, "search", parameterOrderNumber);
      }
      this.filters.end_date = localStorage.hasOwnProperty(
        APPROVED_INVOICES_INDEX_START_DATE_STORAGE_NAME
      )
        ? moment(localStorage.getItem(APPROVED_INVOICES_INDEX_START_DATE_STORAGE_NAME))
        : moment().add(1, "d").format(LARAVEL_DATE_FORMAT);

      this.filters.start_date = localStorage.hasOwnProperty(
        APPROVED_INVOICES_INDEX_END_DATE_STORAGE_NAME
      )
        ? moment(localStorage.getItem(APPROVED_INVOICES_INDEX_END_DATE_STORAGE_NAME))
        : moment().subtract(30, "d").format(LARAVEL_DATE_FORMAT);

      this.getExpiries();
      this.getTableItem();
      this.getOrderNumbers();
      setTimeout(this.onHitBottom, 1000);
    }
  },
  watch: {
    sort() {
      this.filterResult();
    },
    order() {
      this.filterResult();
    },
    isDragging(newValue) {
      if (newValue) {
        this.delayedDragging = true;
        return;
      }
      this.$nextTick(() => {
        this.delayedDragging = false;
      });
    },
  },
};
</script>

<style scoped>
.checked-approved-row {
  opacity: 0.5;
}
</style>
